import React from 'react';
import success from "../../assets/success.gif"

import "./success.css"
const Success = () => {
  return (
    <div className='success-bizi'>
      <img src={success} alt='success'/>

      <p className='success-bizi-para'>We’re always on the lookout for the best fabric to bring back your favorite  and most comfortable Yang set and for the homebody, we present to you the Yang set in this breathable lush cotton. We’re always on the lookout for the best fabric to bring back your favourite  and most comfortable Yang set and for the homebody, we present to you 
      
      </p>
    </div>
  );
}

export default Success;

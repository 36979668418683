import React from 'react';
import "./footer.css"
import logo from "../../assets/whitelogo.png"
import location from "../../assets/location.svg"
import call from "../../assets/call.svg"
import email from "../../assets/email.svg"
import fb from "../../assets/fb.svg"
import insta from "../../assets/insta.svg"
import whatsapp from "../../assets/whatsapp.svg"
import linkdn from "../../assets/linkdn.svg"
import twitter from "../../assets/twitter.svg"

const Footer = () => {
  return (
    <div className='footer-sec'>
      <div className='footer-sec-one'>
      <img src={logo} alt='logo' className='whitelogo-footer' />
      <div className='footer-sec-one-sub'>
        <div className='footer-sec-one-sub-one'>
        <p className='footer-sec-one-pages'>Pages</p>
        <ul>
          <li className='footer-sec-one-home'>Home</li>
          <li className='footer-sec-one-home'>About Us</li>
          <li className='footer-sec-one-home'>Investor Regist..</li>
          <li className='footer-sec-one-home'>Franchise Regis...</li>
          <li className='footer-sec-one-home'>Profile</li>
          <li className='footer-sec-one-home'>Featured News</li>
          <li className='footer-sec-one-home'>Ask Our Experts</li>
        </ul>

        </div>
        <div className='footer-sec-one-sub-two'>
        <p className='footer-sec-one-pages'>Sections</p>
        <ul>
          <li className='footer-sec-one-home'>Explore our Franchies</li>
          <li className='footer-sec-one-home'>featured Listing</li>
          <li className='footer-sec-one-home'>Our Key features &
benifits</li>
          <li className='footer-sec-one-home'>Services</li>
          <li className='footer-sec-one-home'>About</li>
          <li className='footer-sec-one-home'>Why choose us</li>
        </ul>
        </div>
        <div className='footer-sec-one-sub-three'>
        <p className='footer-sec-one-pages'>Reach Us</p>
        <ul>
          <li className='footer-sec-one-home footer-add'>
            <img src={call} alt='logo'/> +91 7799592652
          </li>
          <li className='footer-sec-one-home footer-add'><img src={email} alt='logo'/>vasmigroup@gmail.com</li>
          <li className='footer-sec-one-home footer-add'><img src={location} alt='logo'/> Madhavadhara, Visakhapatnam,530001,india</li>
          
        </ul>

        <p className='footer-sec-one-pages'>Follow us on</p>
        <div className='footer-social'>
          <a href='#' target='_blank'> 
          <img src={fb} alt='logo'/>
          </a>
          <a href='#' target='_blank'> 
          <img src={insta} alt='logo'/>
          </a>
          <a href='#' target='_blank'> 
          <img src={linkdn} alt='logo'/>
          </a>
          <a href='#' target='_blank'> 
          <img src={whatsapp} alt='logo'/>
          </a>
          <a href='#' target='_blank'> 
          <img src={twitter} alt='logo'/>
          </a>
        </div>

          
        </div>
      </div>
      <div className='footer-last-designer'>
      <p className='footer-one-powered'>Powered by <strong> Vasmi Group</strong></p>
      <p className='footer-one-powered'>© 2024 BIZIKNIT, All Rights Reserved.</p>

      </div>

      </div>
      <div className='footer-sec-two'>
        <div className='footer-sec-two-form'>
          <p className='footer-query'>Query</p>
          <form className='form-footer'>
            <input type='text' placeholder='FullName' className='inp-footer' required />
            <input type='text' placeholder='Phone Number' className='inp-footer' required />
            <input type='text' placeholder='Email' className='inp-footer' required />
            <input type='text' placeholder='Who You Are?' className='inp-footer' required />
            <input type='text' placeholder='What You Seek' className='inp-footer' required />
            <select placeholder="select" className='inp-footer'>
              <option>High</option>
              <option>Medium</option>
              <option>Low</option>
            </select>
            <div className='address-footer'>
            <input type='text' placeholder='State' className='inp-footer addresso' required />
            <input type='text' placeholder='City' className='inp-footer addresso' required />
            <input type='text' placeholder='Country' className='inp-footer addresso' required />

            </div>
            <textarea type="text" placeholder='message' className='inp-footer message-footer'/>
            <button className='footer-query-btn'>Submit Your Query</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React from 'react';
import "./Franchise.css"
const FranchiseRegistration = () => {
  return (
    <div className='franchise-register'>

    <div>

      <p className='franchise-head'>More visibility More Business</p>
      <p className='franchise-register-para'>Choose the perfect plan for you, 100% satisfaction guaranteed</p>
      <div className='franchise-register-cards'>
        <div className='franchise-register-card'>
            <p className='franchise-free-listing'>Free Listing</p>

            <p className='franchise-register-money'> <strong>$0</strong>/month </p>

            <li className='franchise-register-lst'>Get Priority over other business listings under - List Page</li>
        </div>
        <div className='franchise-register-card franchise-crd'>
            <p className='franchise-free-listing'>Platinum Listing</p>

            <p className='franchise-register-money'> <strong>$9</strong>/month </p>

            <li className='franchise-register-lst'>Get Priority over other business listings under - Master Category</li>
            <li className='franchise-register-lst'>Visibility & Reach - 100%</li>
        </div>
      </div>
      <button className='franachise-register-btn'>Post</button>
    </div>

    </div>
  );
}

export default FranchiseRegistration;



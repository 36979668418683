import './App.css';
import Home from './screens/home/Home';
import {Header} from './components/Header/Header';
import Footer from './components/footer/Footer';
import Profile from './screens/profile/Profile';
import Investor from './screens/investor/Investor';
import ProductForm from './screens/productform/ProductForm';
import FranchiseForm from './screens/franchiseform/FranchiseForm';
import { Route, Routes } from 'react-router-dom';
import AboutUs from './screens/aboutus/AboutUs';
import Blogs from './screens/blogs/Blogs';
import Blog from './screens/blog/Blog';
import FranchiseRegistration from './screens/FranchiseRegistration/FranchiseRegistration';
import Success from './screens/success/Success';
import Franchises from './screens/Franchises/Franchises';
import Login from './screens/Login/Login';
import Pop from './screens/pop/Pop';
import Portfolio from './screens/portfolio/Portfolio';

function App() {
  return (
    <div className="App">
  <Header/>
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/login' element={<Login/>}/>
    <Route path='/profile' element={<Profile/>}/>
    <Route path='/pop' element={<Pop/>}/>
    <Route path='/portfolio' element={<Portfolio/>}/>
    <Route path='/franchise-form' element={<FranchiseForm/>}/>
    <Route path='/investor-form' element={<Investor/>}/>
    <Route path='/product-form' element={<ProductForm/>}/>
    <Route path='/about-us' element={<AboutUs/>}/>
    <Route path='/blog' element={<Blogs/>}/>
    <Route path='/blogs' element={<Blog/>}/>
    <Route path='/thank-you' element={<Success/>}/>
    <Route path='/franchise' element={<FranchiseRegistration/>}/>
    <Route path='/franchises' element={<Franchises/>}/>

     
    </Routes>
     <Footer/>

    </div>
  );
}

export default App;

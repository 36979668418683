import React from 'react';
import "./portfolio.css"

import arrow from "../../assets/arrow.svg"
const Portfolio = () => {
  return (
    <div  className='portfolio-main'>
    <div className='portfolio-main-one'>
        <div className='portfolio-main-header'>
          <button className='portfolio-main-header-btn'>CFC</button>
          <button className='portfolio-main-header-btnn'>Contact Us</button>
        </div>
        <div className='portfolio-main-side'>
          <p className='portfolio-main-side-para'> <img src={arrow} alt='ddd'/>CFC serves more than 50 hot and cold bevera </p>
          <p className='portfolio-main-side-para'> <img src={arrow} alt='ddd'/>CFC serves more than 50 hot and cold bevera </p>
          <p className='portfolio-main-side-para'> <img src={arrow} alt='ddd'/>CFC serves more than 50 hot and cold bevera </p>
          <p className='portfolio-main-side-para'> <img src={arrow} alt='ddd'/>CFC serves more than 50 hot and cold bevera </p>
          <p className='portfolio-main-side-para'> <img src={arrow} alt='ddd'/>CFC serves more than 50 hot and cold bevera </p>
          <p className='portfolio-main-side-para'> <img src={arrow} alt='ddd'/>CFC serves more than 50 hot and cold bevera </p>
        </div>

        <div className='portfolio-main-foot'>
          <div className='portfolio-main-foot-sub'>
          <p className='portfolio-main-foot-para'>3500 - 5000 Sq.Ft </p>
          <p className='portfolio-main-foot-paraa'>Area Req</p>

          </div>
          <div className='line-vector'/>
          <div className='portfolio-main-foot-sub'>
          <p className='portfolio-main-foot-para'>INR 10000 - 50K</p>
          <p className='portfolio-main-foot-paraa'>Investment Size</p>

          </div>
          <div className='line-vector'/>

          <div className='portfolio-main-foot-sub'>
          <p className='portfolio-main-foot-para'>1000-10000</p>
          <p className='portfolio-main-foot-paraa'>No.Of Franchise Outlets</p>

          </div>
          <div className='line-vector'/>

          <div className='portfolio-main-foot-sub'>
          <p className='portfolio-main-foot-para'>1995</p>
          <p className='portfolio-main-foot-paraa'>establishment Year</p>

          </div>
        </div>
    </div>
    <div className='portfolio-main-two'>
      <p className='portfolio-main-two-para'>CFC Products and Services</p>
      <p className='portfolio-main-two-sub-para'> <img src={arrow} alt='ddd'/>CFC serves more than 50 hot and cold beverages like coffee, tea, milk shakes, mojitos, lassi etc along with over-the-counter prepared tasty eatables like sandwiches, corn, bakery items etc.
      
       </p>
       <p className='portfolio-main-two-sub-para'> <img src={arrow} alt='ddd'/>CFC also serves delicious traditional South Indian breakfast items prepared without the need of any Chef / Master. The breakfast includes Idly, Vada and Upma. For lunch, our customers can enjoy Veg Dum Biryani, Chapathi with Dal Makhani, Parota with Channa Masala. The evening snacks list includes Hara Bhara Kebab, Aloo Samosa, Punjabi Samosa and more.

       </p>
       <p className='portfolio-main-two-sub-para'> <img src={arrow} alt='ddd'/>CFC is rapidly growing in South Indian states Andhra Pradesh, Telangana, Karnataka, Tamilnadu with every one's fav Grandma Andaal Filter coffee. CFC already has presence in the metro cities like Hyderabad and Bengaluru.

       </p>
       <p className='portfolio-main-two-sub-para'> <img src={arrow} alt='ddd'/>Here, You don't need to invest heavily to be a successful business owner. CFC outlets are carefully planned and executed to minimize costs. The operating costs are cut down with our meticulous plan of daily operations. Coupled this with the high margins, you are sure to have a successful business model in your
hand.

       </p>
       <p className='portfolio-main-two-sub-para'> <img src={arrow} alt='ddd'/>CFC outlet requires a mere 50 Sft of space to kickoff. For those with grand plans, we plan and execute up to 2 acres also. The bigger outlets in our experience are proved to be even more lucrative with profit margins reaching up to 80%.
       
       </p>
       

       



    </div>
      <div className='portfolio-main-three'></div>

      <div className='portfolio-main-four'>

      </div>
      
    </div>
  );
}

export default Portfolio;

import React,{useState} from "react";
import "./investor.css";
import whatsapp from "../../assets/whatsappchat.svg";
import color from "../../assets/colorwhatsapp.svg";
import faq from "../../assets/faq.svg";
import { useNavigate } from 'react-router-dom';

const Investor = () => {
//investor form code begin
const navigate = useNavigate();

const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [mobile, setMobile] = useState('');
const [address, setAddress] = useState('');
const [state, setState] = useState('');
const [city, setCity] = useState('');
const [pincode, setPincode] = useState('');
const [industrytype, setIndustrytype] = useState('');
const [investrange, setInvestrange] = useState('');
const [availcapital, setAvailcapital] = useState('');
const [investtimeframe, setInvesttimeframe] = useState('');
const [education, setEducation] = useState('');
const [occupation, setOccupation] = useState('');
const [companyname, setCompanyname] = useState('');
const [businessstate, setBusinessstate] = useState('');
const [businesscity, setBusinesscity] = useState('');
const [readInstructions, setReadInstructions] = useState(false);

// const [nameError, setNameError] = useState('');
// const [emailError, setEmailError] = useState('');
// const [mobileError, setMobileError] = useState('');
// const [addressError, setAddressError] = useState('');
// const [stateError, setStateError] = useState('');
// const [cityError, setCityError] = useState('');
// const [pincodeError, setPincodeError] = useState('');
// const [industrytypeError, setIndustrytypeError] = useState('');
// const [investrangeError, setInvestrangeError] = useState('');
// const [availcapitalError, setAvailcapitalError] = useState('');
// const [investtimeframeError, setInvesttimeframeError] = useState('');
// const [educationError, setEducationError] = useState('');
// const [occupationError, setOccupationError] = useState('');
// const [companynameError, setCompanynameError] = useState('');
// const [business_stateError, setBusinessstateError] = useState('');
// const [business_cityError, setBusinesscityError] = useState('');
// const [readInstructionsError, setReadInstructionsError] = useState(false);
// const handleSubmit = async (event) => {


const handleSubmit = async (event) => {
  event.preventDefault();

  // Assuming form elements have these names (update these if they differ)
  const data = {
    name,
    mobile,
    email,
    address,
    state,
    city,
    pincode,
    industrytype,
    investrange,
    availcapital,
    investtimeframe,
    education,
    occupation,
    companyname,
    businessstate: businessstate, // Assuming typo correction
    businesscity: businesscity,  // Assuming typo correction
    readInstructions,
  };

  try {
    const response = await fetch("https://biziknit.com/api/investor.php",{
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log('Form submission result:', result.message);
    
    // Handle successful response (e.g., display success message)
    if (result.message === 'Your message has been sent successfully!') {
      // Show success message to user (clear form data, etc.)
   
    navigate('/thank-you');
    }

  } catch (error) {
    console.error('Error submitting form:', error.message);

    // Handle errors (e.g., display error message to user)
  }
};


  return (
    <div className="investor">
      <div className="investor-sec">
        <p className="investor-sec-para">Investor Registration</p>
        <form method="post" className="investor-form" onSubmit={handleSubmit}>
          <div className="investor-inp-lab">
            <label>Name </label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter Your Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Mobile Number</label>
            <input
              type="number"
              required
              className="investor-inp-form"
              placeholder="Enter Your Number"
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Email Id (User Id)</label>
            <input
              type="email"
              required
              className="investor-inp-form"
              placeholder="Enter Your Email ID"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Full Address</label>
            <textarea className="investor-inp-form" placeholder="Please Enter Your Address" minLength="6" required spellCheck="true" onChange={(e)=>setAddress(e.target.value)}></textarea>   </div>
          <div className="investor-inp-lab">
            <label>State</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter Your State"
              onChange={(e) => setState(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>City</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter Your City"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Pincode</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Pincode Eg:530047"
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Industry Type (Interested In)</label>
            <select required className="investor-inp-form select-investor" onChange={(e)=>setIndustrytype(e.target.value)}> 
              <option>Industry Type</option>
              <option>Iron</option>
              <option>Furniture</option>
              <option>Food</option>
            </select>
          </div>
          <div className="investor-inp-lab">
            <label>Investment Range</label>
            <select required className="investor-inp-form select-investor" onChange={(e)=>setInvestrange(e.target.value)}>
            <option value="">Select Investment Range</option>
            <option value="1">Rs. 10000 - 50000</option>
            <option value="3">Rs. 50000 - 2lakh</option>
            <option value="5">Rs. 2lakh - 5lakh</option>
            <option value="7">Rs. 5lakh - 10lakh</option>
            <option value="9">Rs. 10lakh - 20lakh</option>
            <option value="11">Rs. 20lakh - 30lakh</option>
            <option value="13">Rs. 30lakh - 50lakh</option>
            <option value="15">Rs. 50lakh - 1 Cr.</option>
            <option value="17">Rs. 1 Cr. - 2 Cr</option>
            <option value="19">Rs. 2 Cr. - 5 Cr</option>
            <option value="21">Rs. 5 Cr. above</option>                                                                                                 
            </select>
          </div>
          <div className="investor-inp-lab">
            <label>Available Captial</label>
            <select required className="investor-inp-form select-investor" onChange={(e)=>setAvailcapital(e.target.value)}>
            <option value="">Select Available Capital</option>
            <option value="1">Rs. 10000 - 50000</option>
            <option value="3">Rs. 50000 - 2lakh</option>
            <option value="5">Rs. 2lakh - 5lakh</option>
            <option value="7">Rs. 5lakh - 10lakh</option>
            <option value="9">Rs. 10lakh - 20lakh</option>
            <option value="11">Rs. 20lakh - 30lakh</option>
            <option value="13">Rs. 30lakh - 50lakh</option>
            <option value="15">Rs. 50lakh - 1 Cr.</option>
            <option value="17">Rs. 1 Cr. - 2 Cr</option>
            <option value="19">Rs. 2 Cr. - 5 Cr</option>
            <option value="21">Rs. 5 Cr. above</option>   
            </select>
          </div>

          <div className="investor-inp-lab">
            <label>How soon would you like to invest</label>
            <select required className="investor-inp-form select-investor" onChange={(e)=>setInvesttimeframe(e.target.value)}>
              <option value="">Select Time Frame</option>
               <option value="1"> Less than 3 months </option>
              <option value="2"> 3 - 6 months </option>
              <option value="3"> 6 - 12 months </option>
            </select>
          </div>
          <div className="investor-inp-lab">
            <label>Education Qualification</label>
            <select required className="investor-inp-form select-investor" onChange={(e)=>setEducation(e.target.value)}>
            <option value="">Select Education Qualification</option>
            <option value="1">Other</option>
            <option value="2">Post graduate</option>
            <option value="3">Higher Secondary</option>
            <option value="4">Graduate</option>
            <option value="5">Diploma</option>
            </select>
          </div>
          <div className="investor-inp-lab">
            <label>Occupation</label>
            <select required className="investor-inp-form select-investor" onChange={(e)=>setOccupation(e.target.value)}>
            <option value="">Select Occupation</option>
            <option value="1">Student</option>
            <option value="2">Service</option>
            <option value="3">Business</option>
            </select>
          </div>
          <div className="investor-inp-lab">
            <label>Company name</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Please Enter Company name"
              onChange={(e) => setCompanyname(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Looking for business in state</label>
            <select required className="investor-inp-form select-investor" onChange={(e)=>setBusinessstate(e.target.value)}>
              <option>Select State</option>
              <option>Furniture</option>
              <option>Food</option>
            </select>
          </div>
          <div className="investor-inp-lab">
            <label>Looking for business in City</label>
            <select required className="investor-inp-form select-investor" onChange={(e)=>setBusinesscity(e.target.value)}>
              <option>Select City</option>
              <option>Furniture</option>
              <option>Food</option>
            </select>
          </div>
          <div className="investor-inp-checkbox">
            <input type="checkbox" placeholder="I have Read Instructions" checked onChange={(e)=>setReadInstructions(e.target.value)}/>
            <label htmlFor="readInstructions">I have Read Instructions</label>
          </div>

          <button className="investor-btn">Register</button>
        </form>
      </div>
      <div className="investor-secone">
        <div className="investor-sec-quarter">
          <img src={whatsapp} alt="quart" />
          <p className="invest-sec-quart-chat">
            Chat On <img src={color} alt="quart" />
          </p>
        </div>
        <div className="investor-sec-half">
          <img src={faq} alt="quart" />
          <p className="invest-faq">Faq</p>
          <p className="invest-faq-sub">What is a Franchise?</p>
          <p className="invest-faq-sub">Who is a investor?</p>
          <p className="invest-faq-sub">Who is a Trade Partner?</p>
          <p className="invest-faq-sub">How can you be an investor?</p>
        </div>
      </div>
    </div>
  );
};

export default Investor;

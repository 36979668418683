import React from 'react';
import blog from "../../assets/blogs.png"
import buisness from "../../assets/buisness.png"

import "./blogs.css"


const Blogs = () => {
  return (
    <div className='blogs'>
    <div className='blogs-sec'>
        <img src={blog} alt='logo'/>
    </div>

    <div className='home-sec-eleven blogs-secu'>

    <div className='home-sec-eleven-cards'>
      <div className='home-sec-eleven-card dec-blog'>
        <img src={buisness} alt='home'/>
        <div className='home-sec-eleven-card-one one-piece'>
        <p className='home-sec-eleven-date'>feb <br/> 09</p>
 
        <p className='home-sec-eleven-para  '>Buisness Meeting</p>
        <p className='home-sec-eleven-sub'>VG offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.
        
        </p>
        <button className='home-sec-eleven-btn'>Read More</button>
        </div>
      </div>

      <div className='home-sec-eleven-card'>
        <img src={buisness} alt='home'/>
        <div className='home-sec-eleven-card-one'>
        <p className='home-sec-eleven-date'>feb <br/> 09</p>
 
        <p className='home-sec-eleven-para  '>Buisness Meeting</p>
        <p className='home-sec-eleven-sub'>VG offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.
        
        </p>
        <button className='home-sec-eleven-btn'>Read More</button>
        </div>
      </div>
      <div className='home-sec-eleven-card'>
        <img src={buisness} alt='home'/>
        <div className='home-sec-eleven-card-one'>
        <p className='home-sec-eleven-date'>feb <br/> 09</p>
 
        <p className='home-sec-eleven-para  '>Buisness Meeting</p>
        <p className='home-sec-eleven-sub'>VG offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.
        
        </p>
        <button className='home-sec-eleven-btn'>Read More</button>
        </div>
      </div>
      <div className='home-sec-eleven-card'>
        <img src={buisness} alt='home'/>
        <div className='home-sec-eleven-card-one'>
        <p className='home-sec-eleven-date'>feb <br/> 09</p>
 
        <p className='home-sec-eleven-para  '>Buisness Meeting</p>
        <p className='home-sec-eleven-sub'>VG offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.
        
        </p>
        <button className='home-sec-eleven-btn'>Read More</button>
        </div>
      </div>
      <div className='home-sec-eleven-card'>
        <img src={buisness} alt='home'/>
        <div className='home-sec-eleven-card-one'>
        <p className='home-sec-eleven-date'>feb <br/> 09</p>
 
        <p className='home-sec-eleven-para  '>Buisness Meeting</p>
        <p className='home-sec-eleven-sub'>VG offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.
        
        </p>
        <button className='home-sec-eleven-btn'>Read More</button>
        </div>
      </div>
    </div>

    </div>
      
    </div>
  );
}

export default Blogs;

import React from "react";
import "../investor/investor.css";
import whatsapp from "../../assets/whatsappchat.svg";
import color from "../../assets/colorwhatsapp.svg";
import faq from "../../assets/faq.svg";

const ProductForm = () => {
  return (
    <div className="investor">
      <div className="investor-sec">
        <p className="investor-sec-para">Property Details</p>
        <form className="investor-form">
          <div className="investor-inp-lab">
            <label>Logo </label>
            <input
              type="file"
              required
              className="investor-inp-form"
              id="logo"
              placeholder="Enter Your Name"
            />
          </div>
          <div className="investor-inp-lab">
            <label>Image for hero section</label>
            <input
              type="file"
              required
              className="investor-inp-form"
              id="logo"
              placeholder="Enter Your Number"
            />
          </div>
          <div className="investor-inp-lab">
            <label>About Products and services</label>
            <input
              type="text"
              required
              className="investor-inp-form products-and"
              placeholder="Enter some benefits to buy Ur franchise "
            />
          </div>
          <div className="investor-inp-lab">
            <label>Floor Area Requirements</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="eg. 3500-4500 Sq.ft"
            />
          </div>
          <div className="investor-inp-lab">
            <label>Investment Size</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="eg. 50000-150000"
            />
          </div>
          <div className="investor-inp-lab">
            <label>No. of current outlets</label>
            <input
              type="number"
              required
              className="investor-inp-form"
              placeholder="eg. 45"
            />
          </div>
          <div className="investor-inp-lab">
            <label>Establishment Year</label>
            <input
              type="number"
              required
              className="investor-inp-form"
              placeholder="eg:1999"
            />
          </div>
          <div className="investor-inp-lab">
            <label>About Products and services</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter in detail about your services 
and products  "
            />
          </div>
          <div className="investor-inp-lab">
            <label>Year commenced operations</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="eg:1990 "
            />
          </div>
          <div className="investor-inp-lab">
            <label>Franchise commenced on</label>
            <input
              type="number"
              required
              className="investor-inp-form"
              placeholder="eg:1990 "
            />
          </div>
          <div className="investor-inp-lab">
            <label>Investment</label>
            <input
              type="number"
              required
              className="investor-inp-form"
              placeholder="eg. 500000"
            />
          </div>

          <div className="investor-inp-lab">
            <label>Franchise/Brand Fee</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="eg. 400000"
            />
          </div>
          <div className="investor-inp-lab multiple-investor">
            <label>With one Time Franchise Fee, You Get</label>
            <div className="time-franchise">
              <input
                type="text"
                required
                className="investor-inp-form"
                placeholder="eg. On site training"
              />
              <input
                type="text"
                required
                className="investor-inp-form"
                placeholder="eg. On site training"
              />
              <input
                type="text"
                required
                className="investor-inp-form"
                placeholder="eg. On site training"
              />
            </div>
          </div>
          <div className="investor-inp-lab">
            <label>Steps you follow for franchise in steps </label>
            <div className="time-franchise">
              <input
                type="text"
                required
                className="investor-inp-form"
                placeholder="eg. Application process"
              />
              <input
                type="text"
                required
                className="investor-inp-form"
                placeholder="eg. Documentation"
              />
              <input
                type="text"
                required
                className="investor-inp-form"
                placeholder="eg. Credit "
              />
            </div>
          </div>
          <div className="investor-inp-lab">
            <label>Give Some points for Store Guidelines</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Store Guidelines"
            />
          </div>
          <div className="investor-inp-lab">
            <label>Give Some points for Man Power</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Some points for Man Power"
            />
          </div>
          <div className="investor-inp-lab">
            <label>Give Some Points for Establishment</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Some Points for Establishment"
            />
          </div>
          <div className="investor-inp-lab">
            <label>Gallery</label>
            <input
              type="file"
              required
              className="investor-inp-form"
              id="logo"
            />
          </div>

          <button className="investor-btn">Submit</button>
        </form>
      </div>
      <div className="investor-secone">
        <div className="investor-sec-quarter">
          <img src={whatsapp} alt="quart" />
          <p className="invest-sec-quart-chat">
            Chat On <img src={color} alt="quart" />
          </p>
        </div>
        <div className="investor-sec-half">
          <img src={faq} alt="quart" />
          <p className="invest-faq">Faq</p>
          <p className="invest-faq-sub">What is a Franchise?</p>
          <p className="invest-faq-sub">Who is a investor?</p>
          <p className="invest-faq-sub">Who is a Trade Partner?</p>
          <p className="invest-faq-sub">How can you be an investor?</p>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;

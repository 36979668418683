import React,{useState} from "react";
import "../investor/investor.css";
import whatsapp from "../../assets/whatsappchat.svg";
import color from "../../assets/colorwhatsapp.svg";
import faq from "../../assets/faq.svg";
import { useNavigate } from 'react-router-dom';

const FranchiseForm = () => {
const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [brdname, setBrdname] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [ownername, setOwnername] = useState("");
  const [owneremail, setOwneremail] = useState("");
  const [ownermobileno, setOwnermobileno] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [industrytype, setIndustrytype] = useState("");
  const [websitelink, setWebsitelink] = useState("");
  const [sector, setSector] = useState("");
  const [cmoperations, setCmoperations] = useState("");
  const [commnencedon, setCommnencedon] = useState("");
  const [currentotl, setCurrentotl] = useState("");
  const [retailotl, setRetailotl] = useState("");
  const [ownedotl, setOwnedotl] = useState("");
  const [termsaccepted, setTermsaccepted] = useState(false); // Initial state for checkbox
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Assuming form elements have these names (update these if they differ)
    const data = {
      name,
      mobile,
      email,
      password,
      brdname,
      companyname,
      ownername,
      owneremail,
      ownermobileno,
      address,
      state,
      city,
      pincode,
      industrytype,
      websitelink,
      sector,
      cmoperations,
      commnencedon,
      currentotl,
      retailotl,
      ownedotl,
      termsaccepted,
    };
  console.log("Form data:", data);
    try {
      const response = await fetch("https://biziknit.com/api/franchisor.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      console.log("Form submission result:", result.status);
  
      if (result.status="success") {
        localStorage.setItem('fran_id',data.email);
        navigate("/product-form");
      } else {
        // Handle form submission errors (e.g., display message to user)
        console.error("Error submitting form:", result.message);
        // You can display a user-friendly error message here
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      // Consider a more user-friendly error handling approach in production
    }
  };

  return (
    <div className="investor">
      <div className="investor-sec">
        <p className="investor-sec-para">Personal Details</p>
        <form className="investor-form" method="post"  onSubmit={handleSubmit}>
          <div className="investor-inp-lab">
            <label>Name </label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter Your Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Mobile Number</label>
            <input
              type="number"
              required
              className="investor-inp-form"
              placeholder="Enter Your Number"
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Email Id (User Id)</label>
            <input
              type="email"
              required
              className="investor-inp-form"
              placeholder="Enter Your Email ID"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Password</label>
            <input
              type="password"
              required
              className="investor-inp-form"
              placeholder="Enter Your Password"
              onChange={(e) => setPassword(e.target.value)}

            />
          </div>
          <p className="investor-sec-para">Business Details</p>

          <div className="investor-inp-lab">
            <label>Brand name</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter Brand name"
              onChange={(e) => setBrdname(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Company name</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter Company name"
              onChange={(e) => setCompanyname(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>CEO / MD / Owner name</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter CEO / MD / Owner name"
              onChange={(e) => setOwnername(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>CEO / MD / Owner Email</label>
            <input
              type="email"
              required
              className="investor-inp-form"
              placeholder="CEO / MD / Owner Email"
              onChange={(e) => setOwneremail(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>CEO / MD / Owner Mobile No.</label>
            <input
              type="number"
              required
              className="investor-inp-form"
              placeholder="Enter Mobile"
              onChange={(e) => setOwnermobileno(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Address Details</label>
            <input
              type="text"
              required
              className="investor-inp-form products-and"
              placeholder="Please Enter Your Address"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>State</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter Your State"
              onChange={(e) => setState(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>City</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Enter Your City"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Pincode</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Pincode Eg:530047"
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Industry Type (Interested In)</label>
            <select required className="investor-inp-form select-investor"
            onChange={(e) => setIndustrytype(e.target.value)}>
              <option value="Industry Type">Industry Type</option>
              <option value="Iron">Iron</option>
              <option value="Furniture">Furniture</option>
              <option value="Food">Food</option>
            </select>
          </div>
          <div className="investor-inp-lab">
            <label>Website Link</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder="Url"
              onChange={(e) => setWebsitelink(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>Sector</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder=""
              onChange={(e) => setSector(e.target.value)}

            />
          </div>
          <div className="investor-inp-lab">
            <label>Year commenced operations</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder=""
              onChange={(e) => setCmoperations(e.target.value)}

            />
          </div>
          <div className="investor-inp-lab">
            <label>Franchise commnenced on</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder=""
              onChange={(e) => setCommnencedon(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>No. of current outlets</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder=""
              onChange={(e) => setCurrentotl(e.target.value)}

            />
          </div>
          <div className="investor-inp-lab">
            <label>No. of retail outlets</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder=""
              onChange={(e) => setRetailotl(e.target.value)}
            />
          </div>
          <div className="investor-inp-lab">
            <label>No. of company owned outlets</label>
            <input
              type="text"
              required
              className="investor-inp-form"
              placeholder=""
              onChange={(e) => setOwnedotl(e.target.value)}

            />
          </div>
          <div className="investor-inp-checkbox">
            <input type="checkbox" placeholder="I have Read Instructions"              
             onChange={(e) => setTermsaccepted(e.target.checked)}/>
            <label htmlFor="readInstructions">
              I Agree to Terms & conditions
            </label>
          </div>

          <button className="investor-btn">Register</button>
        </form>
      </div>
      <div className="investor-secone">
        <div className="investor-sec-quarter">
          <img src={whatsapp} alt="quart" />
          <p className="invest-sec-quart-chat">
            Chat On <img src={color} alt="quart" />
          </p>
        </div>
        <div className="investor-sec-half">
          <img src={faq} alt="quart" />
          <p className="invest-faq">Faq</p>
          <p className="invest-faq-sub">What is a Franchise?</p>
          <p className="invest-faq-sub">Who is a investor?</p>
          <p className="invest-faq-sub">Who is a Trade Partner?</p>
          <p className="invest-faq-sub">How can you be an investor?</p>
        </div>
      </div>
    </div>
  );
};

export default FranchiseForm;

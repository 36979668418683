import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import "./franchises.css"

const Franchises = () => {

    const [filters, setFilters] = useState({
        agricultureFarming: false,
        automobile: false,
        buildingConstruction: false,
        businessServices: false,
        chemicals: false,
        computerHardwareMobileAccessories: false,
        education: false,
        electronicsElectricalSupplies: false,
        environmentPollution: false,
        fashionApparel: false,
        foodBeverages: false,
        furniture: false,
        healthBeauty: false,
        homeSupplies: false,
        hotelSuppliesEquipments: false,
        industrialSupplies: false,
        jewellery: false,
        // Add more filter options as needed
      });
    
      const handleChange = (event) => {
        setFilters({
          ...filters,
          [event.target.name]: event.target.checked,
        });
      };
    
      // Dummy product data for demonstration
      const products = [
        // Sample products for demonstration
        { name: 'Product 1', category: 'agricultureFarming' },
        { name: 'Product 2', category: 'automobile' },
        { name: 'Product 3', category: 'buildingConstruction' },
        // Add more dummy products as needed
      ];
    
    
      // Filter products based on selected checkboxes
      const filteredProducts = products.filter(product => {
        // If any checkbox is checked, only include products that match the checked categories
        return Object.keys(filters).some(filter => filters[filter] && product.category === filter);
      });
  return (
    <div className='franchises'>
      <div className='franchises-main'>
        <div className='franchises-main-left'>
            <p className='franchises-business'>Business categories</p>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.agricultureFarming} onChange={handleChange} name="agricultureFarming" />}
            label="Agriculture & Farming"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.automobile} onChange={handleChange} name="automobile" />}
            label="Automobile"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.buildingConstruction} onChange={handleChange} name="buildingConstruction" />}
            label="Building & Construction"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.businessServices} onChange={handleChange} name="businessServices" />}
            label="Business Services"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.chemicals} onChange={handleChange} name="chemicals" />}
            label="Chemicals"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.computerHardwareMobileAccessories} onChange={handleChange} name="computerHardwareMobileAccessories" />}
            label="Computer Hardware, Mobile & Accessories"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.education} onChange={handleChange} name="education" />}
            label="Education"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.electronicsElectricalSupplies} onChange={handleChange} name="electronicsElectricalSupplies" />}
            label="Electronics & Electrical Supplies"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.environmentPollution} onChange={handleChange} name="environmentPollution" />}
            label="Environment & Pollution"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.fashionApparel} onChange={handleChange} name="fashionApparel" />}
            label="Fashion & Apparel"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.foodBeverages} onChange={handleChange} name="foodBeverages" />}
            label="Food & Beverages"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.furniture} onChange={handleChange} name="furniture" />}
            label="Furniture"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.healthBeauty} onChange={handleChange} name="healthBeauty" />}
            label="Health & Beauty"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.homeSupplies} onChange={handleChange} name="homeSupplies" />}
            label="Home Supplies"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.hotelSuppliesEquipments} onChange={handleChange} name="hotelSuppliesEquipments" />}
            label="Hotel Supplies & Equipments"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.industrialSupplies} onChange={handleChange} name="industrialSupplies" />}
            label="Industrial Supplies"
          />
          <FormControlLabel
            control={<Checkbox sx={{ fontSize: '12px' }} checked={filters.jewellery} onChange={handleChange} name="jewellery" />}
            label="Jewellery"
          />
          {/* Add more checkboxes for other filter options */}
        </FormGroup>
        <FormHelperText>Select categories to filter</FormHelperText>
      </FormControl>

        </div>
        <div className='franchises-main-right'>

        <div>
        <h2>Filtered Products:</h2>
        <ul className='filtered-products-franchises'>
          {filteredProducts.map(product => (
            <div  className='filtered-frnachises'>
            <p className='filtered-frnachises-one'></p>

            <li key={product.name}>{product.name}</li>
            <p className='homo-sec-card-sub'>Business Category Coffee <br/> Investment 10Lac-25Lac <br/> Area Area <br/> Franchise Outlets <br/></p>

<button className='card-home-btn'>View details</button>
            </div>
          ))}
        </ul>
      </div>
        </div>
      </div>
    </div>
  );
}

export default Franchises;

import React, { useState } from 'react'
import "./home.css"
import lando from "../../assets/lando.png"
import homo from "../../assets/homo.png"
import about from "../../assets/about.png"
import turnkey from "../../assets/turnkey.png"
import nationwide from "../../assets/nationwide.png"
import support from "../../assets/support.png"
import buisness from "../../assets/buisness.png"
import vasmi from "../../assets/vasmi.png"
import rahul from "../../assets/rahul.png"
import anitha from "../../assets/anitha.png"
import agv from "../../assets/agv.png"
import brand from "../../assets/brand.svg"
import tech from "../../assets/tech.svg"
import marketing from "../../assets/marketing.svg"
import recruiting from "../../assets/recruitment.svg"
import lead from "../../assets/lead.svg"
import { Button, FormControl, FormControlLabel,  Radio, RadioGroup, TextField } from '@mui/material'

const Home = () => {
    const [selectedValue, setSelectedValue] = useState('female')
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
  
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
  
    const handleInput1Change = (event) => {
      setInput1(event.target.value);
    };
  
    const handleInput2Change = (event) => {
      setInput2(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Perform submission logic here
      console.log('Form submitted:', {
        selectedValue,
        input1,
        input2
      });
    };
  return (
    <div  className='home-sec'>
    <div className='lando-sec'>
        <img src={lando} alt='lando'/>
        <div className='lando-sec-right'>
            <p className='lando-sec-para'>Unlock Your Business Dreams with <strong>BIZIKNIT</strong> Franchising Solutions</p>
            <button className='lando-para-btn'>Get Started</button>

        </div>
    </div>

    <div className='home-sec-two'> 
    
    <div className='home-sec-two-left'>
        <p className='home-sec-two-para'>Why Should I Register? </p>
        <p className='home-sec-two-sub'>Ready to embark on your entrepreneurial journey. Explore our Franchise’s that align with your vision. Let's turn your dreams into a thriving reality! 
        
         </p>
    </div>

    <div className='home-sec-three'>
        <p className='home-sec-three-para'>Are Our Experts</p>
        <form onSubmit={handleSubmit} className='frm-group'>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={selectedValue}
          onChange={handleChange}
          name="radio-buttons-group"
          className='frm-grouphead'
        >
          <FormControlLabel className='frm-radio' value="female" control={<Radio />} label="Expand my Brand" />
          <FormControlLabel className='frm-radio' value="male" control={<Radio />} label="Buy my franchise" />
          <FormControlLabel className='frm-radio' value="other" control={<Radio />} label="Start a business Today" />
        </RadioGroup>
      </FormControl>
      
      <TextField
        label="Input 1"
        variant="outlined"
        value={input1}
        onChange={handleInput1Change}
      />

      <TextField
        label="Input 2"
        variant="outlined"
        value={input2}
        onChange={handleInput2Change}
      />

      <Button type="submit" variant="contained" className='sx-btn' color="primary">
        Submit
      </Button>
    </form>
    </div>
    </div>

    <div className='home-sec-four'>
    <p className='home-sec-four-para'><strong>BIZIKNIT</strong> Franchise Listing Services </p>
    <p className='home-sec-four-sub'>Join the league of successful businesses that have flourished under our guidance.
    
    </p>

    <div className='homo-sec-four'>
        <img src={homo} alt='homo'/>

        <div className='homo-right-sec'>
            <p className='homo-sec-four-para'>Participate in the expansion of a business while enjoying the benefits of its growth. Franchising entails acquiring a share in an established business, allowing you to profit from your own ideas and strategies. Join a thriving enterprise and embark on your journey as a new business professional. Explore the listings below to find one that suits your preferences and commence your entrepreneurial venture.
            
            </p>
            <p className='homo-sec-four-para'> For those initiating a new business with aspirations for expansion, we offer assistance in establishing franchises in diverse locations. We aid in connecting you with suitable partners for your business venture. Simply contact us to feature your business on our website and initiate the process.

            </p>
        </div>
    </div>

    </div>

    <div className='home-sec-five'> 
    <p className='home-sec-five-para'> Explore Franchise’s</p>
    <p className='home-sec-five-sub'> Join the league of successful businesses that have flourished under our guidance.
</p>

<div className='home-sec-five-cards'>
    <div className='home-sec-card'>
        <img src={agv} alt='homo'/>
        <p className='homo-sec-card-para'>AGV</p>
        <p className='homo-sec-card-sub'>Business Category Coffee <br/> Investment 10Lac-25Lac <br/> Area Area <br/> Franchise Outlets <br/></p>

        <button className='card-home-btn'>View details</button>
    </div>
    <div className='home-sec-card'>
        <img src={agv} alt='homo'/>
        <p className='homo-sec-card-para'>AGV</p>
        <p className='homo-sec-card-sub'>Business Category Coffee <br/> Investment 10Lac-25Lac <br/> Area Area <br/> Franchise Outlets <br/></p>

        <button className='card-home-btn'>View details</button>
    </div>
    <div className='home-sec-card'>
        <img src={agv} alt='homo'/>
        <p className='homo-sec-card-para'>AGV</p>
        <p className='homo-sec-card-sub'>Business Category Coffee <br/> Investment 10Lac-25Lac <br/> Area Area <br/> Franchise Outlets <br/></p>

        <button className='card-home-btn'>View details</button>
    </div>
</div>
    </div>
    <div className='home-sec-five'> 
    <p className='home-sec-five-para'>Featured Listings</p>
   

<div className='home-sec-five-cards'>
    <div className='home-sec-card'>
        <img src={agv} alt='homo'/>
        <p className='homo-sec-card-para'>Franchise Name</p>
        <p className='homo-sec-card-sub'>Business Category Coffee <br/> Investment 10Lac-25Lac <br/> Area Area <br/> Franchise Outlets <br/></p>

        <button className='card-home-btn'>View details</button>
    </div>
    <div className='home-sec-card'>
        <img src={agv} alt='homo'/>
        <p className='homo-sec-card-para'>Franchise Name</p>
        <p className='homo-sec-card-sub'>Business Category Coffee <br/> Investment 10Lac-25Lac <br/> Area Area <br/> Franchise Outlets <br/></p>

        <button className='card-home-btn'>View details</button>
    </div>
    <div className='home-sec-card'>
        <img src={agv} alt='homo'/>
        <p className='homo-sec-card-para'>Franchise Name</p>
        <p className='homo-sec-card-sub'>Business Category Coffee <br/> Investment 10Lac-25Lac <br/> Area Area <br/> Franchise Outlets <br/></p>

        <button className='card-home-btn'>View details</button>
    </div>
</div>
    </div>

    <div className='home-sec-six'>
      <p className='home-sec-six-para'>Discover the power of franchising with BIZIKNIT Your Partner in Business Success.</p>
    <button className='discover-now'>Register Now</button>
    </div>

    <div className='home-sec-seven'>
    <p className='home-sec-five-para para-brand'> Additional <strong>Services</strong></p>
    <div className='home-sec-seven-cards'>
    <div className='home-sec-seven-details'>
      <img src={brand} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Branding</p>
        <p className='home-sec-seven-right-sub'>Distinguish your business from mere brands with our refined branding support for your franchise. We craft a compelling brand presence that empowers businesses to resonate with their target audience effectively.
        
        </p>
      </div>

      </div>
      <div className='home-sec-seven-details'>
      <img src={lead} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Lead Generation</p>
        <p className='home-sec-seven-right-sub'>For new businesses or franchises grappling with finding the right leads, our lead generation support ensures you're well-prepared. We meticulously select the most fitting leads tailored to your business needs.

        </p>
      </div>

      </div>
      <div className='home-sec-seven-details'>
      <img src={marketing} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Marketing</p>
        <p className='home-sec-seven-right-sub'>Count on us for comprehensive marketing support to propel your new business. Our effective marketing strategies are designed to reach your customers, promote your franchise, and connect with potential clientele responsibly.

        </p>
      </div>

      </div>
      <div className='home-sec-seven-details'>
      <img src={recruiting} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Recruiting</p>
        <p className='home-sec-seven-right-sub'>We sought out the right talent for your business who can integrate with your operations, providing tailored solutions to meet your workforce needs. For a franchise that is about to start, our staffing services are of great help to solve your staffing needs.

        </p>
      </div>

      </div>
      <div className='home-sec-seven-details'>
      <img src={tech} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Tech Support</p>
        <p className='home-sec-seven-right-sub'>Foster your business growth with cutting-edge technology support. We employ the latest technology, ensuring you stay updated and trendy, enhancing your ability to connect with customers seamlessly. Trust us to keep your business at the forefront of innovation.

        </p>
      </div>

      </div>
    </div>
    </div>
    <div className='home-sec-eight'>
      <p className='home-sec-eight-about'>About <strong>BIZIKNIT</strong></p>
      <div className='homo-sec-four'>
        <img src={about} alt='homo'/>

        <div className='homo-right-sec seco'>
            <p className='homo-sec-four-para'>At BIZIKNIT, we unravel the mysteries of entrepreneurship. Franchising isn't just a concept; it's your compass, guiding you through the intricate journey of business ownership. We understand the struggles and the uncertainties, and we're here to illuminate the path to your dreams.

            </p>
            
        </div>
    </div>
    </div>

    <div className='home-sec-nine'>
    <p className='home-sec-eight-about'>Our Key Features & Benifits</p>

    <div className='home-sec-nine-cards'>
      <div className='home-sec-nine-card'>
        <img src={turnkey} alt='turnkey'/>
        <p className='home-sec-nine-card-para'>Turnkey Solutions</p>
        <p className='home-sec-nine-card-sub'>Your business is ready to launch. We provide a seamless setup, so you can focus on what you do best – driving your brand forward.
        
        </p>
      </div>
      <div className='home-sec-nine-card'>
        <img src={nationwide} alt='turnkey'/>
        <p className='home-sec-nine-card-para'>Nationwide Opportunities</p>
        <p className='home-sec-nine-card-sub'> Explore franchise options spanning the length and breadth of the country. Your brand, reaching every corner, making an impact.

        </p>
      </div>
      <div className='home-sec-nine-card'>
        <img src={support} alt='turnkey'/>
        <p className='home-sec-nine-card-para'>Comprehensive Support</p>
        <p className='home-sec-nine-card-sub'>From staffing to branding, lead generation to technical support - we've got your back at every step. Your success is our mission.

        </p>
      </div>
    </div>

    </div>
    <div className='home-sec-ten'>
    <p className='home-sec-eight-about'>Why <strong>Choose Us</strong></p>

    <p className='home-sec-ten-para'>
    You may be interested in understanding what sets <strong>BIZIKNIT</strong>  apart as the preferred choice for pursuing a franchise opportunity. We have diligently endeavored to provide comprehensive support across various aspects. The following are compelling reasons to consider us over alternative options:

Risk-Free Proposition: Opting for <strong>BIZIKNIT</strong> ensures a risk-free choice, as we extend 100% backup support to address any challenges that may arise in the course of your business operations.

We offer dedicated assistance by providing the necessary staffing resources on our end, streamlining the operational aspects of your franchise.

At <strong>BIZIKNIT</strong>, we actively contribute to your business success by assisting in identifying and attracting the right customer base for your franchise.

Beyond merely facilitating franchise sales, we go the extra mile by taking charge of marketing initiatives to promote and enhance the visibility of your business, ensuring it reaches your target audience effectively.

    </p>

    </div>
    <div className='home-sec-eleven'>
    <p className='home-sec-eight-about'><strong>Featured </strong> News</p>

    <div className='home-sec-eleven-cards'>
      <div className='home-sec-eleven-card'>
        <img src={buisness} alt='home'/>
        <div className='home-sec-eleven-card-one'>
        <p className='home-sec-eleven-date'>feb <br/> 09</p>
 
        <p className='home-sec-eleven-para  '>Buisness Meeting</p>
        <p className='home-sec-eleven-sub'>VG offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.
        
        </p>
        <button className='home-sec-eleven-btn'>Read More</button>
        </div>
      </div>
    </div>

    </div>
    <div className='home-sec-twlve'>
    <p className='home-sec-eight-about'><strong>BIZIKNIT </strong> Success Stories</p>


    <div className='home-sec-seven-details sec-twlve-card-home'>
      <img src={rahul} alt='lobo'/>

      <div className='home-sec-seven-right '>
        <p className='home-sec-seven-right-para colo'>Rahul's Tale </p>
        <p className='home-sec-seven-right-sub colo'>Rahul, a budding entrepreneur, transformed his local business into a nationwide sensation with BIZIKNIT's franchising expertise. His dream, now a reality, echoes the possibilities that await you.

        </p>
      </div>

      </div>
      <div className='home-sec-seven-details sec-twlve-card-home anitha'>
      <img src={anitha} alt='lobo'/>

      <div className='home-sec-seven-right '>
        <p className='home-sec-seven-right-para colo'>Anita's Triumph </p>
        <p className='home-sec-seven-right-sub colo'>Anita, a visionary business owner, expanded her horizons with our support. Today, her franchise stands as a testament to the heights achievable through strategic franchising.

        </p>
      </div>

      </div>

    </div>
    <div className='home-sec-thirteen'>
    <p className='home-sec-eight-about'>Our Other<strong>Brands </strong></p>

    <div className='home-sec-thirteen-cards'>
      <div className='home-sec-thirteen-card'>
        <img src={vasmi} alt='lobo'/>
      </div>
      <div className='home-sec-thirteen-card'>
        <img src={vasmi} alt='lobo'/>
      </div>
      <div className='home-sec-thirteen-card'>
        <img src={vasmi} alt='lobo'/>
      </div>
    </div>

    </div>
    <div className='home-sec-categories'>
      
    </div>
    </div>
  )
}

export default Home

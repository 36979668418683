import React, { useState } from 'react';
import "./login.css"

const Login = () => {
  const [activeTab, setActiveTab] = useState('login');
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  };

  return (
    <div className='login-main'>
      <div className='login-main-one'>
        <div className='login-btn-main'>

        {
          activeTab === "forgot" ? (
            <p className='frg-password-head'>Forgot Password ?</p>
          ):(
<div className='fexi-password'>

          <button className={activeTab === 'login' ? 'active-tab btn-log-in' : 'btn-log-register'} onClick={() => handleTabChange('login')}>Login</button>
          <button className={activeTab === 'register' ? 'active-tab btn-log-in' : 'btn-log-register'} onClick={() => handleTabChange('register')}>Register</button>
</div>
       
          )
        }
        </div>

        {activeTab === 'login' &&  (
          <form className='login-form'>
            <div className='login-form-inps'>
              <label>Email</label>
              <input className='inp-login' placeholder='example@gmail.com' required type='email'/>
            </div>
            <div className='login-form-inps'>
              <label>Password</label>
              <input className='inp-login' placeholder='password' required type='password'/>
            </div>
            <a href='#' className='forgot-password'  onClick={() => handleTabChange('forgot')}>Forgot Password..?</a>
            <button className='submit-login'>Submit</button>
            <p>New to Biziknit? <a href='#' className='forgot-password' onClick={() => handleTabChange('register')}>Register Here</a></p>
          </form>
        )}

        {activeTab === "forgot" && (
          <div className='forgot-password-form'>
            <div className='login-form-inps'>
              <label>Email</label>
              <input className='inp-login' placeholder='example@gmail.com' required type='email'/>
            </div>
            <div className='login-form-inps'>
              <label>Create Password</label>
              <input className='inp-login' placeholder='password' required type='password'/>
            </div>
            <div className='login-form-inps'>
              <label>Confirm Password</label>
              <input className='inp-login' placeholder='confirm password' required type='password'/>
            </div>
            <button className='submit-login'>Reset Password</button>
            <p>Remember Password! <a href='#' className='forgot-password' onClick={() => handleTabChange('login')}>Login Here</a></p>

          </div>
        )}

        {activeTab === 'register' && (
          <div className='register-group'>
            <button className='register-group-btn'>Start a business today (Investor Registration)</button>
            <button className='register-group-btn'>Franchise Registration</button>
            <button className='registered-already-login'>Registered User? <a href='#' className='forgot-password'>Login here</a></button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./profile.css";
import edit from "../../assets/edit.svg"
import save from "../../assets/save.svg"


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, date, fat) {
  return { name, date, fat };
}

const rows = [
  createData('CFC', "22/2/2024", "Pending"),
  createData('Tea Time', "11/1/2024", "Succeed"),

];

const Profile = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [placeholder, setPlaceholder] = useState("");

  const handleFocus = () => {
    setPlaceholder("Dob");
  };

  const handleBlur = () => {
    setPlaceholder("");
  };
  return (
    <div className="profile">
      <div className="profile-sec-one">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab sx={{color:"red"}} label="Profile" value="1" />
                <Tab sx={{color:"red"}} label="Change PassWord" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <form className="profile-form">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="profile-inp-form"
                  required
                />
                <input
                  type="number"
                  placeholder="Mobile"
                  className="profile-inp-form"
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="profile-inp-form"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  className="profile-inp-form"
                  required
                />
                <input
                  type="date"
                  placeholder={placeholder}
                  className="profile-inp-form"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                />
                <input
                  type="text"
                  placeholder="Address"
                  className="profile-inp-form"
                  required
                />
                <input
                  type="text"
                  placeholder="City"
                  className="profile-inp-form"
                  required
                />
                <input
                  type="text"
                  placeholder="State"
                  className="profile-inp-form"
                  required
                />
                <input
                  type="number"
                  placeholder="PinCode"
                  className="profile-inp-form"
                  required
                />
                <input
                  type="text"
                  placeholder="Country"
                  className="profile-inp-form"
                  required
                />
              </form>

              <button className="profile-btn" type="submit">
                Save
              </button>
            </TabPanel>
            <TabPanel value="2">
              <form className="form-security">
                <input
                  type="password"
                  placeholder="New Password"
                  required
                  className="profile-inp-form"
                />
                <input
                  type="password"
                  placeholder="Retype Password"
                  required
                  className="profile-inp-form"
                />
              </form>
              <button className="profile-btn" type="submit">
                Save
              </button>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="profile-sec-two">

        <p className="franchise">Franchise Status</p>

        <TableContainer component={Paper} sx={{width:"80%",borderRadius:"25px"}}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={{ background: "linear-gradient(101deg, #FE3732 30.86%, #FA5209 70.68%)" }}>
          <TableRow>
            <StyledTableCell>Franchise Name</StyledTableCell>
            <StyledTableCell align="right">Posted on</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
            <StyledTableCell align="right">Update/Edit</StyledTableCell>
            <StyledTableCell align="right">Share</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.date}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right"><img src={edit} alt="edit"/></StyledTableCell>
              <StyledTableCell align="right"><img src={save} alt="edit"/></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </div>
    </div>
  );
};

export default Profile;

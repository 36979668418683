import React from 'react';
import about from "../../assets/aboutus.png"
import client from "../../assets/client.png"
import brand from "../../assets/brand.svg"
import tech from "../../assets/tech.svg"
import marketing from "../../assets/marketing.svg"
import recruiting from "../../assets/recruitment.svg"
import lead from "../../assets/lead.svg"
import "./aboutus.css"
const AboutUs = () => {
  return (
    <div className='aboutus'>
    <div className='aboutus-sec'>
    <img src={about} alt='background'/>
    <div className='about-us-sec-one'>
        <p className='about-us-sec-one-para'>Fueling growth for individuals and businesses via franchising</p>
        <p className='about-us-sec-one-sub'>Enhancing individual and business growth through innovative franchising solutions. Find your path to success with our specialized services
        
        </p>
        <button className='about-us-btn'>Contact US</button>
    </div>

    </div>
    <div className='about-use-sec-two'>
        <p className='about-us-sec-two-para'>SOME REASONS TO WORK TOGETHER</p>
        <p className='about-us-sec-two-head'>Our Goal is Client’s Success</p>
        <p className='about-us-sec-two-sub'>We provide services for startups to enterprises looking for a partner for their digital presence. We work with you, not for you.
        
        </p>

        <div className='about-sec-two-one'>
            <div className='about-sec-two-left'>
                <p className='about-sec-two-left-para'>Our Vision:</p>
                <p className='about-sec-two-left-sub'>To be the best in the world of digital, helping our clients to achieve their business goals and objectives, We see a future where every business has a string online presence and can reach their target audiences easily and effectively.
                
                </p>
                <p className='about-sec-two-left-para'>Our Mission:</p>
                <p className='about-sec-two-left-sub'>To help businesses leverage the power of digital to reach their customers and grow their business. We are committed to providing our clients with the best possible ROI, and we are always working hard to exceed their expectations.

                </p>
            </div>
    <img src={client} alt='background'/>

        </div>
        <div className='home-sec-seven'>
        <p className='home-sec-five-para para-brand'> Our <strong> Services</strong></p>
    <div className='home-sec-seven-cards'>
    <div className='home-sec-seven-details'>
      <img src={brand} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Branding</p>
        <p className='home-sec-seven-right-sub'>Distinguish your business from mere brands with our refined branding support for your franchise. We craft a compelling brand presence that empowers businesses to resonate with their target audience effectively.
        
        </p>
      </div>

      </div>
      <div className='home-sec-seven-details'>
      <img src={lead} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Lead Generation</p>
        <p className='home-sec-seven-right-sub'>For new businesses or franchises grappling with finding the right leads, our lead generation support ensures you're well-prepared. We meticulously select the most fitting leads tailored to your business needs.

        </p>
      </div>

      </div>
      <div className='home-sec-seven-details'>
      <img src={marketing} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Marketing</p>
        <p className='home-sec-seven-right-sub'>Count on us for comprehensive marketing support to propel your new business. Our effective marketing strategies are designed to reach your customers, promote your franchise, and connect with potential clientele responsibly.

        </p>
      </div>

      </div>
      <div className='home-sec-seven-details'>
      <img src={recruiting} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Recruiting</p>
        <p className='home-sec-seven-right-sub'>We sought out the right talent for your business who can integrate with your operations, providing tailored solutions to meet your workforce needs. For a franchise that is about to start, our staffing services are of great help to solve your staffing needs.

        </p>
      </div>

      </div>
      <div className='home-sec-seven-details'>
      <img src={tech} alt='lobo'/>

      <div className='home-sec-seven-right'>
        <p className='home-sec-seven-right-para'>Tech Support</p>
        <p className='home-sec-seven-right-sub'>Foster your business growth with cutting-edge technology support. We employ the latest technology, ensuring you stay updated and trendy, enhancing your ability to connect with customers seamlessly. Trust us to keep your business at the forefront of innovation.

        </p>
      </div>

      </div>
    </div>
    </div>

    </div>

    <div className='about-sec-three'>
        <p className='about-sec-three-para'>Turn more visitors into clients with Bizinit</p>
        <input type='email' placeholder='Enter your Email' className='about-sec-three-inp'/>
        <button className='email-btn'>Submit</button>
    </div>
      
    </div>
  );
}

export default AboutUs;

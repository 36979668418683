import React from 'react';
import "./pop.css"

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


const Pop = () => {
  return (
    <div className='pop-main'>
    <div className='pop-main-one'>
        <p className='our-experts-pop'>Ask Our Experts</p>
  <FormControl>
      
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Expand my Brand" control={<Radio />} label="Expand my Brand" />
        <FormControlLabel value="male" control={<Radio />} label="Buy a Franchise" />
        <FormControlLabel value="Start A Business Today" control={<Radio />} label="Start A Business Today" />
      
      </RadioGroup>
    </FormControl>
      <form className='pop-form-inp'>
        <input className='inp-login' placeholder='Enter Your Name' type='text'/>
        <input className='inp-login' placeholder='Enter Your State' type='text'/>
        <input className='inp-login' placeholder='Email' type='email'/>
        <input className='inp-login' placeholder='Enter Your City' type='text'/>
        <input className='inp-login' placeholder='Enter Your Number' type='number'/>
        <input className='inp-login' placeholder='Enter Address' type='text'/>
        <input className='inp-login' placeholder='Enter Pincode' type='number'/>
        <select className='inp-login slct-pop'>
          <option>1lakh</option>
          <option>2lakh</option>
        </select>
        
      </form>
      <button className='btn-log-in pop-btn'>Submit Request</button>
    </div>
      
    </div>
  );
}

export default Pop;

import React from 'react';

import blog from "../../assets/blog.png"
import "./blog.css"

const Blog = () => {
  return (
    <div className='blog'>

    <div className='blog-sec-one'>
        <img src={blog} alt='logo'/>
        <p className='blog-sec-one-art'>Business meeting</p>
        <p className='blog-sec-one-art-para'>Apex offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more. Apex offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.Apex offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.Apex offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.Apex offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.Apex offers a range of services including design, engineering, and project management.
        
        </p>
    </div>
      
    </div>
  );
}

export default Blog;

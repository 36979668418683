import React, { useEffect, useRef, useState } from "react";

import logo from "../../assets/logo.svg";

import more from "../../assets/more.png";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import "./header.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";





const useDropdown = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const closeDropdown = () => setIsOpen(false);

  return { isOpen, toggleDropdown, closeDropdown };

};



export const Header = () => {

  const navigate = useNavigate();

  const { isOpen: isServicesOpen, toggleDropdown: toggleServicesDropdown, closeDropdown: closeServicesDropdown } = useDropdown();

  const { isOpen: isMobileOpen, toggleDropdown: toggleMobileDropdown, closeDropdown: closeMobileDropdown } = useDropdown();

  const servicesDropdownRef = useRef(null);

  const mobileDropdownRef = useRef(null);



  const handleHtmlClick = (event) => {

    if ((servicesDropdownRef.current && !servicesDropdownRef.current.contains(event.target)) &&

        (mobileDropdownRef.current && !mobileDropdownRef.current.contains(event.target))) {

      closeServicesDropdown();

      closeMobileDropdown();

    }

  };

  useEffect(() => {

    document.addEventListener('click', handleHtmlClick);



    return () => {

      document.removeEventListener('click', handleHtmlClick);

    };

  }, []);



  const handleServiceClick = (route) => {

    navigate(route);

    closeServicesDropdown();

    closeMobileDropdown();



  };




  return (

    <div className="heade">

      <img src={logo} alt="logo" className="logo" onClick={() => navigate("/")} style={{ cursor: "pointer" }} />

      <div className="mobile-more">

        <li ref={mobileDropdownRef}>

          <a href="#!" onClick={toggleMobileDropdown}>

            <img src={more} alt="more" />

          </a>

          <ul className="nav-dropdown mobile-drp" style={{ display: isMobileOpen ? "block" : "none" }}>

           

            <li>

              <a href="#!" onClick={() => handleServiceClick("/")}>Home</a>

            </li>

            <li>

              <a href="#!" onClick={() => handleServiceClick("/about-us")}>About Us</a>

            </li>
            <li>

              <a href="#!" onClick={() => handleServiceClick("/product-form")}>Product Form</a>

            </li>
            <li>

              <a href="#!" onClick={() => handleServiceClick("/framchise-form")}>Franchise Form</a>

            </li>

            <li>

              <a href="#!" onClick={() => handleServiceClick("/profile")}>Profile</a>

            </li>

          </ul>

        </li>

      </div>

      <div className="head-one">

        <ul>

          <Link className="link" to="/">Home</Link>
          <Link className="link" to="/about-us">About Us</Link>

          <li ref={servicesDropdownRef}>

            <a href="#!" className="heade-services" onClick={toggleServicesDropdown}>Categories <ExpandMoreIcon /></a>

            <ul className="nav-dropdown" style={{ display: isServicesOpen ? "block" : "none" }}>
            <div className="heade-cato">
      <div>
        <ul className="heade-categories">
          <li>Agriculture & Farming</li>
          <li>Automobile</li>
          <li>Building & Construction</li>
          <li>Business Services</li>
          <li>Chemicals</li>
          <li>Education</li>
          <li>Electronics & Electrical Supplies</li>
          <li>Environment & Pollution</li>
          <li>Fashion & Apparel</li>
        </ul>
      </div>
      <div>
        <ul className="heade-categories">
          <li>Food & Beverages</li>
          <li>Furniture</li>
          <li>Health & Beauty</li>
          <li>Home Supplies</li>
          <li>Hotel Supplies & Equipments</li>
          <li>Industrial Supplies</li>
          <li>Jewellery, Gemstones & Astrology</li>
          <li>Medical & Hospital Supplies</li>
          <li>Office & School Supplies</li>
        </ul>
      </div>
      <div>
        <ul className="heade-categories">
          <li>Pharmaceuticals</li>
          <li>Printing & Publishing</li>
          <li>Retail & E-Retail</li>
          <li>Scientific & Laboratory Instrument</li>
          <li>Sports, Fitness & Entertainment</li>
          <li>Telecommunication</li>
          <li>Textile, Fabrics & Accessories</li>
          <li>Toys & Games</li>
          <li>Agents, Dealers & Distributors</li>
        </ul>
      </div>
    </div>

            </ul>

          </li>

          <Link className="link" to="/investor-form">Investor</Link>
          <Link className="link" to="/blog">Blog</Link>

          <Link className="link" to="/product-form">Product Form</Link>

        </ul>

        <button className="btn-explore">Login/Register</button>

      </div>

    </div>

  );

};